:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 45vh;
    /* Colors */
    --text-color: #1D1E2C;
    --main-color: #fff;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #1D1E2C;
    --header-bg-color: #fff;
    --footer-bg-color: #1D1E2C;
    --footer-text-color: #fff;
    --menu-bg-color: inherit;
    --menu-text-color: #fff;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: #515d92;
    --menu-text-bg-hover-color: #414a75;
    /* Typo */
    --scale-factor: 1.5;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Lato, sans-serif;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Lato.css";

/*
@import "/cssmodules/photoswipe.css";
@import "/cssmodules/animate.css";
*/

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
    background: #f5f5f5;
}
.mfp-with-zoom img {
    width: 100%;
    max-width: 100vh;
    max-height: 228vh !important;
    padding: 0;
}

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

.importantinfo {
 background: #515d92;
color:white;   
    padding: 2em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
}

header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
    z-index: 100;
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
}

.logo a {
   padding: 1rem;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    display: block;
    outline: 0 !important;
}

:not(.slick-slide) > img{
    height: auto;
}

.flex img {
    width: 100%;
}

figure {
margin:0;
}


  
.topimagewrapper {
height:250px;
background-size: cover;
}

.topimagewrapper div {
max-width: var(--container-max-width);
width:100%;

}

.topimagewrapper div button {
color: #fff;
border-radius: 0px;
}

.book.button, .download.button {
color: #fff;
border-radius: 0px;
border:none;
margin:0;
text-transform: uppercase;
font-weight: 700;
font-size: 1.2rem;
}

.book.button {
background: var(--menu-text-bg-hover-color);
margin-right: 1rem;
}

.download.button {
background: #121212;
}

.tripinfowrapper {
padding-bottom: 2rem;
margin-bottom: 2rem;
border-bottom: 5px solid var(--menu-text-bg-hover-color);
}

.resor main a {

    text-decoration: none;
    background: var(--menu-text-bg-hover-color);
    color: #fff;
    text-align: center;
}

.resor main a figure {

    margin: .5rem 0 .5rem 0;

  
}

.resor main a figure img {
    margin: 0 auto;
   
    border: 2px solid rgba(255,255,255,0.8);
}

.resor main a h2 {

   margin:0;
    color: #fff;
    font-size: 1.2rem;
    min-height: 50px;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    margin-top: 0;
    margin-bottom: 1rem;
    color: #515d92;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #515d92;
}

h3 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--menu-bg-color);
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .6rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin: 0;
    background: #121212;
}

.slick-slide {

    background-size: cover;
    opacity:.6;
     transition: opacity 2s ease-in-out;
}

.slick-slide.slick-center {
    opacity:1;
   
}


/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
padding: 1rem;
}

.boltform input[type=text], .boltform input[type=email], .boltform select,  .boltform textarea {
 width:100%;
}

.boltform button {
 background: var(--menu-text-bg-hover-color); 
 color: var(--menu-text-hover-color);
 border: none;
    margin: 0;
}

.boltform button:hover {
 background: color(var(--menu-text-bg-hover-color) lightness(60%)); 
 color: var(--menu-text-hover-color);
}

.boltform .boltforms-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}


label[for="bokaresa_name"], label[for="bokaresa_email"], label[for="bokaresa_address"], label[for="bokaresa_zipcity"], label[for="bokaresa_phone"] {
display:none;
}

.topinfo {
 background: #1D1E2C;
 color: #fff;
 font-size: .8rem;
}

.topinfo > div {

display:inline-block;
padding: .3rem;
}

.intenditfooter {
font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

strong a {
background: yellow;
text-transform: uppercase;
padding: .2rem; 
}

/* .multicolumns {
column-count: 2;
column-gap: 16px;
 
}

.multicolumns p {
display: inline-block;

} */

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 50vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
    .topinfo {
 display: none;
}
    
    .resor .flex img {
  width: auto; 
}
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}
